const orderSliceState = {
    step1FormData: {
        _id: null,
        started: false,

        gifterName: "",
        gifterEmail: "",

        recipientName: "",
        songLanguage: "English",
        recipientPronounce: "",
        recipientNameFile: "",
        mentionRecipientInSong: true,

        recipientRelationship: null,

        occasion: null,
        mentionOccasionInSong: true,

        existsInDB: false,
        isAiSong: true,
    },
    step2FormData: {
        _id: null,
        artistIds: [],
        musicTraits: [],
        AIGender: "",

        existsInDB: false,
    },
    previewSong: {
        hadPreviewSong: false,
        disablePreviewSong: false,
        justWasAtPreviewSong: false,
        earlyCheckout: false,
    },
    step3FormData: {
        orderId: null,
        aboutSong: "",
        orderTopicDtos: [],

        existsInDB: false,
    },
    step4FormData: {
        orderId: null,
        deliveryDate: null,
        billingAddressDto: {
            fullName: "",
            phoneNumber: "",
            billingAddress1: "",
            billingAddress2: "",
            city: "",
            state: "",
            zipCode: "",
        },
        paymentMethodDto: {
            cardNumber: "",
            cardHolderName: "",
            expirationDate: "",
            cvv: "",
        },
        couponName: "",

        existsInDB: false,
        instantSongZohoSent: false,
        instantSong_hash: 0,

        selectedPaymentMethod: "paypal",
    },
    currentArtist: {
        firstName: "",
        lastName: "",
        stageName: "",
        pfpPath: "",
    },
    secondaryArtist: {
        firstName: "",
        lastName: "",
        stageName: "",
        pfpPath: "",
    },
    gender: null,

    artistSelected: false,
    needHelp: false,

    addonInCustomSong: [],

    previousAddons: false,

    noCreditOffset: false,

    paypalReady: false,

    paypalError: false as boolean | string,
    lastTotalAmount: 0.0,
    useCalculateOrderSumariesVersion: 1,

    lyricsEditor: {
        editLyricsOpen: false,
        editLyricsChanged: false,
        editLyricsGenerate: 'ended',
        editLyricsEdit: 'closed',
        editSongName: "Unnamed Song",
        editSongLyrics: "",
        firstTimeAutoPlay: true,
    },

    previousOrderDetails: {
        orderId: null,
        lastKnownTotal: 0.0,
        numberOfSongs: 1,
        productName: "",
        couponName: "",
        goto_account: false,
    }
}
const authSliceState = {
    user: null,
    savedPath: null,
    resetPasswordProps: null,
}

export {
    authSliceState,
    orderSliceState,
}