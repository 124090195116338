import {
    createSlice
} from '@reduxjs/toolkit';
import {
    orderSliceState
} from '~config/constants/default-values';
import {
    accountApi
} from '~screens/account/services/accountApi';
import {
    orderApi
} from '../services/orderApi';

const orderSlice = createSlice({
    name: 'order',
    initialState: orderSliceState,
    reducers: {
        setStep1FormData: (state, action) => {
            state.step1FormData =
            {
                ...state.step1FormData,
                ...action.payload
            }
        },
        setStep2FormData: (state, action) => {
            state.step2FormData =
            {
                ...state.step2FormData,
                ...action.payload
            }
        },
        setArtistSelected: (state: any, action) => {
            state.artistSelected = action.payload
        },
        setSecondaryArtist: (state, action) => {
            state.secondaryArtist
                = action.payload
        },
        setGender: (state, action) => {
            state.gender = action.payload
        },



        repalceMusicTrait: (state, action) => {

            state.step2FormData = {
                ...state.step2FormData,
                musicTraits: [
                    ...(state.step2FormData.musicTraits.filter((obj: any) => obj.traitType != action.payload.traitType)),

                    ...((!action.payload._id ? [] : [
                        {
                            _id: action.payload._id,
                            traitType: action.payload.traitType,
                        }]))
                ] as never[]
            };

        },


        setNeedhelp: (state: any, action) => {
            state.needHelp = action.payload
        },
        setResetStep2: (state) => {
            state.step2FormData = {
                ...state.step2FormData,
                artistIds: [],
                musicTraits: []
            }

            state.currentArtist
                = orderSliceState.currentArtist

            state.secondaryArtist
                = orderSliceState.secondaryArtist

            state.gender
                = orderSliceState.gender

            state.artistSelected
                = orderSliceState.artistSelected

            state.needHelp
                = orderSliceState.needHelp
        },

        setStep3FormData: (state, action) => {

            state.step3FormData =
            {
                ...state.step3FormData,
                ...action.payload
            }
        },


        setPreviewSong: (state, action) => {

            state.previewSong =
            {
                ...state.previewSong,
                ...action.payload
            }
        },
        setCurrentArtist: (state, action) => {
            state.currentArtist
                = action.payload
        },

        resetOrderSteps: (stable, action) => {
            return {
                ...orderSliceState,
                ...action.payload,
            }
        },
        setStep4FormData: (state, action) => {
            state.step4FormData =
            {
                ...state.step4FormData,
                ...action.payload
            }
        },
        pushAddonInCustomSong: (state: any, action) => {
            state.addonInCustomSong = [
                ...state.addonInCustomSong,
                action.payload,
            ]
        },
        removeAddonFromCustomSong: (state: any, action) => {
            state.addonInCustomSong
                = state.addonInCustomSong
                    .filter((obj: any) =>
                        obj
                        != action.payload
                    )
        },
        setPreviousAddons: (state, action) => {

            state.previousAddons = action.payload
        },
        setNoCreditOffset: (state, action) => {

            state.noCreditOffset = action.payload
        },
        setPaypalReady: (state, action) => {
            state.paypalReady = action.payload
        },

        setPaypalError: (state, action) => {
            state.paypalError = action.payload
        },

        setUseCalculateOrderSumariesVersion: (state, action) => {
            state.useCalculateOrderSumariesVersion = action.payload
        },

        setLastTotalAmount: (state, action) => {
            state.lastTotalAmount = action.payload
        },

        setLyricsEditor: (state, action) => {
            state.lyricsEditor = { ...state.lyricsEditor, ...action.payload }
        },

        setPreviousOrderDetails: (state, action) => {
            state.previousOrderDetails = { ...state.previousOrderDetails, ...action.payload }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                accountApi
                    .endpoints
                    .billingAddress
                    .matchFulfilled,
                (state, { payload }) => {

                    state.step4FormData = {
                        ...state.step4FormData,
                        ...payload,
                    }
                }
            )
            .addMatcher(
                orderApi
                    .endpoints
                    .previousOrderSummaries
                    .matchFulfilled,
                (state, { payload }) => {

                    if (payload?.length > 0)
                        state.previousAddons = true
                    else
                        state.previousAddons = false
                }
            )
    }
});

export const {
    setGender,
    setNeedhelp,
    setResetStep2,
    resetOrderSteps,
    repalceMusicTrait,
    setPaypalReady,
    setPaypalError,
    setPreviewSong,
    setStep1FormData,
    setStep2FormData,
    setStep3FormData,
    setStep4FormData,
    setCurrentArtist,
    setPreviousAddons,
    setArtistSelected,
    setNoCreditOffset,
    setSecondaryArtist,
    setLastTotalAmount,
    pushAddonInCustomSong,
    removeAddonFromCustomSong,
    setLyricsEditor,
    setPreviousOrderDetails,
    setUseCalculateOrderSumariesVersion,
} = orderSlice.actions;

export default orderSlice.reducer;